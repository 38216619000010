import request from '../utils/request'


export function getAboutList(params) {

    return request({

        url: 'activities/list'
        , method: 'get'
        , params

    })


}

export function getDetail(id) {

    return request({

        url: 'activities/detail/' + id
        , method: 'get'

    })


}

export function getTdSelect(params) {

    return request({

        url: 'td/select'
        , method: 'get'
        , params

    })


}
export function getKsSelect(params) {

    return request({

        url: 'ks/select'
        , method: 'get'
        , params

    })


}
export function getYhhdSelect(params) {

    return request({

        url: 'yhhd/select'
        , method: 'get'
        , params

    })


}
