<template>
  <div class="scrolllayout" id="scroll">
    <div class="scrolllayout-flex">
      <div class="home">

        <div class="home-service">
          <div class="home-service-top">
            {{ homeobj.serviceobj.title }}
          </div>
          <div class="home-service-center" @click="_clickLy">
            <div class="home-service-center-left">
              <img :src="homeobj.serviceobj.servicecontent.chaticon" class="home-service-center-left-img" alt="">
            </div>
            <!--            <div class="home-service-center-right">
                          {{ homeobj.serviceobj.servicecontent.content }}
                        </div>-->
          </div>
          <div class="home-service-bottom">
            <div class="home-service-bottom-left">
              <img :src="homeobj.serviceobj.servicecontent.phoneicon" class="home-service-bottom-left-img" alt="">
            </div>
            <div class="home-service-bottom-right">
              {{ homeobj.serviceobj.servicecontent.phonenumber }}
            </div>
          </div>
        </div>

        <nav1></nav1>
        <div class="home-banner">
          <div class="home-banner-top">
            <el-carousel style="height: 100%" trigger="click" height="481px">
              <el-carousel-item v-for="v in d_swiper" :key="v.id">
                <a :href="v.url?v.url:'#'">
                <img  style="width: 100%;object-fit: cover;height: 481px" :src="v.img.url"/>
                </a>
              </el-carousel-item>
            </el-carousel>
            <!--            <hswiper v-if="d_swiper.length>0" :swiper="d_swiper"></hswiper>-->

          </div>
          <div class="home-banner-bottom">
            <div class="home-banner-bottom-unit" style="display: flex;flex-direction: column;align-items: center">

              <div style="display: flex;flex-direction: row;flex-wrap: wrap;width: 700px">
                <div class="banner-frame" style="margin-right: 20px;margin-bottom: 20px" :key="v.id"
                     v-for="v in homeProject"
                     @click="_clickJump('about?type=4&id='+v.id)">
                  <img style="width: 100%;height: 100%" :src="v.home_bg.url"/>
                  <div class="b-item-frame"
                  >
                    <img style="width: 30px;height: 30px" :src="v.home_icon.url"/>
                    <div class="i-item-frame">
                      <div class="i-title">{{ v.name }}</div>
                      <div class="i-txt">{{ v.intro }}</div>
                    </div>
                    <img style="width: 16px;height: 16px" src="../images/treatwhite.png">
                  </div>
                </div>
                <div class="banner-frame" style="margin-right: 20px;margin-bottom: 20px" :key="i"
                     v-for="i in (9-homeProject.length)">
                  <img style="width: 100%;height: 100%" src="../images/banner8.png"/>
                  <div class="b-item-frame"
                  >
                    <div class="i-item-frame"
                         style="display: flex;flex-direction: column;justify-content: center;align-items: center;justify-content: center">

                      <img style="width: 30px;margin-bottom: 10px" src="../images/jqqd.png"/>
                      <div>敬请期待</div>
                    </div>
                  </div>
                </div>
              </div>

              <!--              <div style="display: flex;flex-direction: row">

                              <div class="banner-frame" style="margin-right: 20px" @click="_clickJump('about?type=4&id=7')">
                                <img style="width: 100%;height: 100%" src="../images/banner2.png"/>
                                <div class="b-item-frame"
                                >
                                  <img style="width: 30px" src="../images/bannericon2.png"/>
                                  <div class="i-item-frame">
                                    <div class="i-title">华西牙科汕头口腔医院</div>
                                    <div class="i-txt">汕头口腔医院将传承百年华西之基，弘扬改革创新之道，恪守服务百姓之责。</div>
                                  </div>
                                  <img style="width: 16px;height: 16px" src="../images/treatwhite.png">
                                </div>
                              </div>

                              <div class="banner-frame" style="margin-right: 20px" @click="_clickJump('about?type=4&id=6')">
                                <img style="width: 100%;height: 100%" src="../images/banner1.png"/>
                                <div class="b-item-frame"
                                >
                                  <img style="width: 30px" src="../images/bannericon1.png"/>
                                  <div class="i-item-frame">
                                    <div class="i-title">欧瑞健康体检中心</div>
                                    <div class="i-txt">广东欧瑞健康管理服务有限公司是一家致力于大健康产业的深度开发、投资及运营管理公司。</div>
                                  </div>
                                  <img style="width: 16px;height: 16px" src="../images/treatwhite.png">
                                </div>
                              </div>
                              <div class="banner-frame" @click="_clickJump('about?type=4&id=17')">
                                <img style="width: 100%;height: 100%" src="../images/banner6.jpg"/>
                                <div class="b-item-frame"
                                >
                                  <img style="width: 30px" src="../images/bannericon6.png"/>
                                  <div class="i-item-frame">
                                    <div class="i-title">汕头欧瑞眼科医院</div>
                                    <div class="i-txt">宾至欧瑞，是一次愉快的健康体验，更是一次健康之旅。欧瑞健康热情欢迎您！</div>
                                  </div>
                                  <img style="width: 16px;height: 16px" src="../images/treatwhite.png">
                                </div>
                              </div>

                            </div>


                            <div style="display: flex;flex-direction: row;margin-top: 20px">
                              <div class="banner-frame" style="margin-right: 20px" @click="_clickJump('about?type=4&id=29')">
                                <img style="width: 100%;height: 100%" src="../images/banner7.png"/>
                                <div class="b-item-frame"
                                >
                                  <img style="width: 30px" src="../images/bannericon7.png"/>
                                  <div class="i-item-frame">
                                    <div class="i-title">欧瑞医学影像中心</div>
                                    <div class="i-txt">宾至欧瑞，是一次愉快的健康体验，更是一次健康之旅。欧瑞健康热情欢迎您！</div>
                                  </div>
                                  <img style="width: 16px;height: 16px" src="../images/treatwhite.png">
                                </div>
                              </div>
                              <div class="banner-frame" style="margin-right: 20px" @click="_clickJump('about?type=4&id=16')">
                                <img style="width: 100%;height: 100%" src="../images/banner5.jpg"/>
                                <div class="b-item-frame"
                                >
                                  <img style="width: 60px" src="../images/bannericon5.png"/>
                                  <div class="i-item-frame" style="margin-top: 18px">
                                    <div class="i-title">欧瑞视光配镜中心</div>
                                    <div class="i-txt">宾至欧瑞，是一次愉快的健康体验，更是一次健康之旅。欧瑞健康热情欢迎您！</div>
                                  </div>
                                  <img style="width: 16px;height: 16px" src="../images/treatwhite.png">
                                </div>
                              </div>
                              <div class="banner-frame" @click="_clickJump('about?type=4&id=10')">
                                <img style="width: 100%;height: 100%" src="../images/banner3.png"/>
                                <div class="b-item-frame"
                                >
                                  <img style="width: 30px" src="../images/bannericon3.png"/>
                                  <div class="i-item-frame">
                                    <div class="i-title">金域检验</div>
                                    <div class="i-txt">宾至欧瑞，是一次愉快的健康体验，更是一次健康之旅。欧瑞健康热情欢迎您！</div>
                                  </div>
                                  <img style="width: 16px;height: 16px" src="../images/treatwhite.png">
                                </div>
                              </div>


                            </div>
                            <div style="display: flex;flex-direction: row;margin-top: 20px">
                              <div class="banner-frame" style="margin-right: 20px" @click="_clickJump('about?type=4&id=11')">
                                <img style="width: 100%;height: 100%" src="../images/banner4.png"/>
                                <div class="b-item-frame"
                                >
                                  <img style="width: 30px" src="../images/bannericon4.png"/>
                                  <div class="i-item-frame">
                                    <div class="i-title">母婴社区项目</div>
                                    <div class="i-txt">宾至欧瑞，是一次愉快的健康体验，更是一次健康之旅。欧瑞健康热情欢迎您！</div>
                                  </div>
                                  <img style="width: 16px;height: 16px" src="../images/treatwhite.png">
                                </div>
                              </div>
                              <div class="banner-frame" style="margin-right: 20px">
                                <img style="width: 100%;height: 100%" src="../images/banner8.png"/>
                                <div class="b-item-frame"
                                >
                                  <div class="i-item-frame"
                                       style="display: flex;flex-direction: column;justify-content: center;align-items: center;justify-content: center">

                                    <img style="width: 30px;margin-bottom: 10px" src="../images/jqqd.png"/>
                                    <div>敬请期待</div>
                                  </div>
                                </div>
                              </div>
                              <div class="banner-frame">
                                <img style="width: 100%;height: 100%" src="../images/banner9.png"/>
                                <div class="b-item-frame"
                                >
                                  <div class="i-item-frame"
                                       style="display: flex;flex-direction: column;justify-content: center;align-items: center;justify-content: center">

                                    <img style="width: 30px;margin-bottom: 10px" src="../images/jqqd.png"/>
                                    <div>敬请期待</div>
                                  </div>
                                </div>
                              </div>


                            </div>-->
              <div class="blueblock">
                <img :src="homeobj.bannerobj.blueblock" class="blueblock-img" alt="">
              </div>
            </div>
            <!--            <div class="home-banner-bottom-unit" v-for="(v,i) in homeobj.bannerobj.bannerlist"
                             @click="_clickJump(v.router)" :key="i">
                          <div class="home-banner-bottom-unit-ab">
                            <div class="home-banner-bottom-unit-icon" :style="v.bannericonobj.bannericonstyle">
                              <img class="home-banner-bottom-unit-icon-img" :src="v.bannericonobj.bannericon" alt="">
                            </div>
                            <div class="home-banner-bottom-unit-title">
                              {{ v.title }}
                            </div>
                            <div class="home-banner-bottom-unit-content">
                              {{ v.content }}
                            </div>
                            <div class="home-banner-bottom-unit-treatewhite">
                              <img class="home-banner-bottom-unit-treatewhite-img" :src="homeobj.bannerobj.treatewhite" alt="">
                            </div>
                          </div>
                          <img class="home-banner-bottom-unit-img" :src="v.banner">
                        </div>-->

          </div>
        </div>
        <div class="home-content">

          <el-carousel trigger="click" height="481px">
            <el-carousel-item v-for="v in x_swiper" :key="v.id">
              <a :href="v.url?v.url:'#'">
                <img style="width: 100%" :src="v.img.url"/></a>
            </el-carousel-item>
          </el-carousel>
          <!--          <hswiper v-if="x_swiper.length>0" :swiper="x_swiper"></hswiper>-->


          <div class="home-content-newsarea">
            <div class="home-content-newstitle">
              <div class="home-content-newstitle-top">{{ homeobj.newsobj.title }}</div>
              <div class="home-content-newstitle-bottom">{{ homeobj.newsobj.englishtitle }}</div>
            </div>

            <div class="home-content-news">


              <div style="display: flex;flex-direction: row;position: relative">
                <div @click="_clickJump('about?type=5')"
                     style="position: absolute;cursor: pointer;right: 0;top: -25px;font-size: 17px;z-index: 2;color: #1BBDA9;font-weight: bold;">
                  更多
                </div>

                <div class="home-content-news-unit" v-for="(v,i) in newslist" @click="_clickJump('detail?id='+v.id)"
                     :key="i">
                  <div class="home-content-news-unit-top">
                    <img :src="v.img.url" class="home-content-news-unit-top-img" alt="">
                  </div>
                  <div class="home-banner-news-unit-bottom">
                    <div class="home-banner-news-unit-bottom-title">
                      {{ v.name }}
                    </div>
                    <div class="home-banner-news-unit-bottom-content">
                      {{ v.intro }}
                    </div>
                    <div class="home-banner-news-unit-bottom-date">
                      {{ _getDate(v) }}
                    </div>
                    <div class="home-banner-news-unit-bottom-newsgeen">
                      <img class="home-banner-news-unit-bottom-newsgeen-img" :src="homeobj.newsobj.newssgreen" alt="">
                    </div>
                  </div>
                  <div class="home-banner-news-unit-greenblock"></div>
                </div>
              </div>
            </div>
          </div>


          <div class="embracehealth"></div>

        </div>
      </div>
    </div>
    <footer1></footer1>


  </div>
</template>

<script>
//导航栏组件
import nav1 from "../components/nav/nav1";
//页脚组件
// import footer1 from "../components/footer/footer1.vue";
//banner组图片
import banner1 from '/src/images/banner1.png';
import banner2 from '/src/images/banner2.png';
import banner3 from '/src/images/banner3.png';
import banner4 from '/src/images/banner4.png';
//banner组图标
import bannericon1 from '/src/images/bannericon1.png';
import bannericon2 from '/src/images/bannericon2.png';
import bannericon3 from '/src/images/bannericon3.png';
import bannericon4 from '/src/images/bannericon4.png';
//banner组白色加号图标
import treatewhite from '/src/images/treatwhite.png';
//底层蓝色方框
import blueblock from '/src/images/blueblock.png'

//swiper组件
// import hswiper from "../components/swiper/hswiper";
//新闻图片
// import news1 from "/src/images/news1.png"
//新闻单元加号
import newssgreen from "/src/images/newsgreen.png"
//页脚组件
import footer1 from "../components/footer/footer1";
//聊天图标
import chaticon from "/src/images/chaticon.png";
//电话图标
import phoneicon from "/src/images/phoneicon.png";
import {createLy, getHomeProject, getSwiperSelect} from "@/api/home";
import {getAboutList} from "@/api/about";
import {parseTime} from "@/utils";

export default {
  data() {
    return {

      x_swiper: [],
      d_swiper: [],
      homeProject: [],
      newslist: [],
      form: {
        content: ''
        , mobile: ''
      },
      homeobj: {
        //上方横幅链接
        bannerTopLink: '',


        //客服对象
        serviceobj: {
          title: '在线咨询客服',
          //在线客服咨询中间
          servicecontent: {
            //聊天图标
            chaticon,
            //立即留言
            content: '',
            //电话图标
            phoneicon,
            phonenumber: '0754-88889956',
          },
          link: '',
        },

        //横幅
        bannerobj: {
          //白色加号图标
          treatewhite,
          //底层的蓝色方框
          blueblock,
          //banner组
          bannerlist: [
            {
              //banner图片
              banner: banner1,
              //banner图标
              bannericonobj: {
                //图标地址
                bannericon: bannericon1,
                //图标样式
                bannericonstyle: 'width:28px;height:33px',
              },
              router: 'detail?id=6',
              //banner标题
              title: '欧瑞健康体检中心',
              //banner内容
              content: '广东欧瑞健康管理服务有限公司是一家致力于大健康产业的深度开发、投资及运营管理公司。',
            },
            {
              banner: banner2,
              //banner图标
              bannericonobj: {
                bannericon: bannericon2,
                bannericonstyle: 'width:24px;height:24px',
              },
              router: 'detail?id=7',

              title: '华西牙科汕头口腔医院',
              content: '汕头口腔医院将传承百年华西之基，弘扬改革创新之道，恪守服务百姓之责。',
            },
            {
              banner: banner3,
              //banner图标
              bannericonobj: {
                bannericon: bannericon3,
                bannericonstyle: 'width:24px;height:26px',
              },
              router: 'detail?id=10',
              title: '金域检验',
              content: '宾至欧瑞，是一次愉快的健康体验，更是一次健康之旅。欧瑞健康热情欢迎您！',
            },
            {
              banner: banner4,
              //banner图标
              bannericonobj: {
                bannericon: bannericon4,
                bannericonstyle: 'width:23px;height:26px',
              },
              router: 'detail?id=11',
              title: '康养项目',
              content: '宾至欧瑞，是一次愉快的健康体验，更是一次健康之旅。欧瑞健康热情欢迎您！',
            },
          ],
        },


        //新闻对象
        newsobj: {
          //主题
          title: '新闻资讯',
          //英文标题
          englishtitle: 'NEWS INFORMATION',

          //新闻单元加号
          newssgreen,
          //新闻列表
          newslist: []
        },
      },
    }
  },
  components: {
    nav1,
    footer1,
  },
  created() {
    // window.addEventListener('resize',this.setblueblockleft)
    // this.setblueblockleft()


    this._getSwiperSelect()

    this._getNewsInforMation();

    this._getHomeProduct()


  },
  methods: {
    _getHomeProduct() {


      getHomeProject().then(res => {

        this.homeProject = res;

        console.log(res);

      });

    },

    _createLy() {


      createLy(this.form).then(() => {


        this.form = {
          content: ''
          , mobile: ''
        };
        this.dialogFormVisible = false;
        this.$message.success('已留言，请耐心等待')


      });


    },

    onJump(e) {

      if (e) {
        this.$router.push({path: e})
      }
    },

    _clickLy() {

      this.dialogFormVisible = true;
    },


    // setblueblockleft(){
    //   this.$nextTick(()=>{
    //
    //     console.log(this.$refs.bannerButtom.clientWidth);
    //     console.log(this.$refs.bannerButtom);
    //
    //     let bannerButtom = this.$refs.bannerButtom
    //     console.log(bannerButtom.offsetLeft,bannerButtom.offsetWidth)
    //   })
    // }


    _clickJump(e) {

      this.$router.push({path: e})

    },
    _getDate(e) {

      return parseTime(e.time, '{y}-{m}-{d}')

    },
    _getNewsInforMation() {

      getAboutList({type: 5, page: 1, pageSize: 3}).then((res) => {

        this.newslist = res.data;


      });

    },

    _getSwiperSelect() {

      getSwiperSelect().then(res => {

        this.d_swiper = res['d'];
        this.x_swiper = res['x'];


      });

    },

    handlerouter(e) {
      this.$router.push(e)
    }
  },
}
</script>

<style scoped>
.home {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}


.home-banner {
  position: relative;
  width: 100%;
  min-width: 1200px;
}

.home-banner-top {
  /*background: url("../images/banner.png") no-repeat center center;*/
  /*background-size: cover;*/
  /*-moz-background-size: cover;*/
  height: 481px;
  cursor: pointer;
}

.home-banner-bottom {
  /*position: absolute;*/
  /*top: 410px;*/
  /*left: 50%;*/
  /*transform: translateX(-50%);*/
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  margin-top: -50px;
  position: relative;
  z-index: 99;
}

.home-banner-bottom-unit {
  position: relative;
  color: white;
  margin-top: 14px;
  cursor: pointer;

}


.home-banner-bottom-unit-ab {
  position: absolute;
  bottom: 35px;
  left: 33px;
}

.home-banner-bottom-unit-treatewhite {
  width: 16px;
  height: 15px;
}

.home-banner-bottom-unit-treatewhite-img {
  display: block;
  width: 100%;
  height: 100%;
}

.home-banner-bottom-unit-content {
  width: 183px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 13.5px 0;
}

.home-banner-bottom-unit-title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 8.5px;
}

.home-banner-bottom-unit-img {
  display: block;
  width: 100%;
  height: 100%;
}

.home-banner-bottom-unit-icon-img {
  width: 100%;
  height: 100%;
}

.blueblock {
  position: absolute;
  height: 120px;
  width: 120px;
  top: 450px;
  right: -20px;
  z-index: -1;
}

.blueblock-img {
  width: 100%;
  height: 100%;
  display: block;
}

.home-content {
  margin-top: 35px;
  width: 100%;
  min-width: 1200px;
}

.home-content-news {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.home-content-news-unit {
  background: white;
  width: 303px;
  margin-left: 8px;
  position: relative;
  cursor: pointer;

}

.home-content-news-unit:first-of-type {
  margin-left: 0;
}

.home-content-newsarea {
  margin-top: 38.3px;
}

.home-content-newstitle {
  font-size: 34px;
  font-weight: bold;
  color: #333333;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-content-newstitle-bottom {
  font-size: 23.8px;
  margin-top: 5.2px;
  margin-bottom: 23.1px;
}

.home-content-news-unit-top {
  width: 303px;
  height: 160px;
}

.home-content-news-unit-top-img {
  width: 100%;
  height: 100%;
  display: block;
}

.home-banner-news-unit-greenblock {
  width: 8px;
  height: 20px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 150px;
  background: var(--line-height-color);
}

.home-banner-news-unit-bottom-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 17px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5.65px;
  padding: 0 10px;
}

.home-banner-news-unit-bottom-content {
  width: 222px;
  margin: 0 auto 6.75px auto;
  font-size: 13.6px;
  color: #777777;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 37px;
}

.home-banner-news-unit-bottom-date {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 13.6px;
  color: #777777;
}

.home-banner-news-unit-bottom-newsgeen {
  display: flex;
  flex-direction: row-reverse;
}

.home-banner-news-unit-bottom-newsgeen-img {
  width: 19px;
  height: 18px;
  display: block;
}

.embracehealth {
  background: url("../images/embracehealth.png") no-repeat center center;
  background-size: cover;
  -moz-background-size: cover;
  height: 360px;
  position: relative;
  margin-top: 50px;
}

.el-button--primary {
  background-color: #1BBDA9;
  border-color: #1BBDA9;
}


</style>

<style lang="scss">
.banner-frame {
  position: relative;
  width: 220px;
  height: 170px;

  &:nth-child(3n) {

    margin-right: 0 !important;

  }

  .b-item-frame {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px 19px;
    display: flex;
    flex-direction: column;

    .i-item-frame {
      flex: 1;
      margin-top: 10px;

      .i-title {
        font-weight: bold;
        font-size: 17px;

      }

      .i-txt {
        font-size: 13px;
        margin-top: 10px;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
}
</style>